import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import * as Yup from "yup";
import { emailRegex } from "../../../../../../helpers/expirationTime";
import MyDropdown from "../../../../../atoms/MyDropdown";
import MyInput from "../../../../../atoms/MyInput";
import { useEffect, useState } from "react";
import { getData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useNavigate } from "react-router-dom";

const AddForms = ({ selectedTermSheet, selectedDocuments }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [preClient, setPreClient] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [clientAndPreClient, setClientAndPreClient] = useState();

  const initialValues = {
    pre_client_id: "",
    pre_client_name: "",
    external_entity: "",
    ex_signer_first_name: "",
    ex_signer_last_name: "",
    ex_signer_email: "",
  };
  const validationSchema = Yup.object().shape({
    external_entity: Yup.string().required("Extrernal entity is required"),
    ex_signer_first_name: Yup.string().required("First name is required"),
    ex_signer_last_name: Yup.string().required("Last name is required"),
    ex_signer_email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
  });
  const getPreClient = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Pre-Client",
        },
      });
      setPreClient(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getClient = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Client",
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getPreClient();
    getClient();
  }, []);

  useEffect(() => {
    if (preClient || clientData) {
      const mergedArray = clientData.concat(preClient);
      setClientAndPreClient(mergedArray);
    }
  }, [preClient, clientData]);

  const handleSubmit = async (values) => {
    console.log("values", values);
    let payload = {};
    if (selectedDocuments === "Forms") {
      switch (selectedTermSheet) {
        case "Credit Application Form":
        case "Personal Financial Statement":
        case "Catalyst Release Form":
        case "Trade Reference Form(Full)":
        case "Trade Reference Form(No Doc Request)":
        case "Supplier Contact List":
        case "Customer Contact List":
        case "Debt Schedule":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              external_entity: values?.external_entity,
            },
          };
          break;
        default:
          break;
      }
    }
    try {
      const res = await postData({
        endpoint: "documents/create/documents",
        token: cookies.t,
        data: payload,
      });
      if (res) {
        toast.success("Documents Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/tools");
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          dirty,
          setFieldValue,
          setValues,
          setFieldTouched,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => {
          return (
            <>
              <div className="mt-4">
                <MyDropdown
                  placeholder="Client or Pre-Client Name"
                  data={clientAndPreClient}
                  onChange={(option) => {
                    setFieldValue("pre_client_id", option?.value);
                    setFieldValue("pre_client_name", option?.label);
                  }}
                  format="all"
                  touched={touched.pre_client_name}
                  selectedDate={values.pre_client_name}
                  errors={errors.pre_client_name}
                  handleBlur={(e) => {
                    setFieldTouched("pre_client_name", true);
                    handleBlur(e);
                  }}
                  width="32.426%"
                />
              </div>
              <div className="mt-4">
                <Heading className="HeadingStyle">Signer Selection</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  <MyInput
                    type="text"
                    name="external_entity"
                    customholder="External Entity"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_first_name"
                    customholder="External Signer First Name"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_last_name"
                    customholder="External Signer Last Name"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_email"
                    customholder="External Signer Email"
                    width="32.426%"
                  />
                </div>
              </div>
              <div className={style.buttonWrap}>
                <button
                  className="btn btn-primary w-30 mt-2 btn-xl"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Generate Document
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default AddForms;
