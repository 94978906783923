import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  CarrierNameData,
  counterPartyType,
  CurrencyData,
  GICSectorData,
  LimitTypeData,
  PolicyNameData,
  ReportingData,
  statusData,
  statusTypes,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";

const ReportsInsurance = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [portfolioData, setPortfolioData] = useState([]);
  const [filters, setFilters] = useState({});

  const getFundingData = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getAllFundingsByInsurance",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          transactionType: filters?.["Transaction Type"],
          status: filters?.["Status"]
            ? filters?.["Status"]
            : ["Funded", "Closed"],
          currency: filters?.["Currency"],
          carrier_name: filters?.["Carrier Name"],
          policy_name: filters?.["Policy name"],
          limit_type: filters?.["Limit Type"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    }
  };
  useEffect(() => {
    getFundingData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  function getPopulateName(data) {
    switch (data.transaction_type) {
      case "Note":
      case "Revolver":
      case "Client":
        return data?.client_name;

      case "Factoring/AR":
      case "Obligor":
        return data?.obligor_name;

      case "Supplier Credit":
      case "Supplier Credit-1":
      case "Supplier":
        return data?.supplier_name;

      default:
        return "";
    }
  }

  const columns = [
    {
      field: "Insured Name",
      headerName: "Insured Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{getPopulateName(params.row)}</div>
        );
      },
    },
    {
      field: "Funder",
      headerName: "Funder",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.funder}</div>;
      },
    },
    {
      field: "Policy Name",
      headerName: "Policy Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row.insuranceDetails?.[0]?.policy_name}
          </div>
        );
      },
    },
    {
      field: "Carrier Name",
      headerName: "Carrier Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.insuranceDetails?.[0]?.carrier_name}
          </div>
        );
      },
    },
    {
      field: "Limit",
      headerName: "Limit",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.insuranceDetails?.[0]?.limit}
          </div>
        );
      },
    },
    {
      field: "Type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.insuranceDetails?.[0]?.limit_type}
          </div>
        );
      },
    },
    {
      field: "Currency",
      headerName: "Currency",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.currency}</div>;
      },
    },
    {
      field: "Face value",
      headerName: "Face value",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{params?.row.face_value}</span>
          </>
        );
      },
    },
    {
      field: "Funding Date",
      headerName: "Funding Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.due_date).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Transaction Type",
      headerName: "Transaction Type",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{params.row?.transaction_type}</span>
          </>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{params.row?.status}</span>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Insurance created yet!"
          tag="Add Counterparties"
          type="Counterparty"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    Currency: CurrencyData,
    "Carrier Name": CarrierNameData,
    "Policy name": PolicyNameData,
    "Limit Type": LimitTypeData,
    Status: [
      { label: "Funded", value: "Funded" },
      { label: "Closed", value: "Closed" },
    ],
    "Transaction Type": transactionType,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportInsurance",
      token: cookies.t,
      fileName: "Insurance",
      params: {
        search: searchData,
        transactionType: filters?.["Transaction Type"],
        status: filters?.["Status"]
          ? filters?.["Status"]
          : ["Funded", "Closed"],
        currency: filters?.["Currency"],
        carrier_name: filters?.["Carrier Name"],
        policy_name: filters?.["Policy name"],
        limit_type: filters?.["Limit Type"],
        reporting_terms: filters?.["Reporting terms"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Insurances Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Insurance</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>
      <CustomDatagrid
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {portfolioData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={portfolioData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default ReportsInsurance;
