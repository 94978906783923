import moment from "moment";
import style from "../index.module.scss";
import CustomButton from "../../../../../atoms/CustomButton";
import { Formik } from "formik";
import MyInput from "../../../../../atoms/MyInput";
import { CurrencyData } from "../../../../../../utils/staticData";
import MyDropdown from "../../../../../atoms/MyDropdown";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";

const EditFunding = ({ fundedDataById }) => {
  const handleSubmit = (value) => {
    console.log("value", value);
    console.log("fundedDataById", fundedDataById);
  };
  const initialValues = {
    cost_value: "",
    face_value: "",
    currency: "",
    invoice_date: "",
    due_date: "",
    funding_date: "",
    Participant: "",
  };
  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrapEdit}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Asset Information</th>
              <th scope="col"></th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            <Formik
              initialValues={initialValues}
              key={1234567}
              onSubmit={handleSubmit}
            >
              {({
                isValid,
                dirty,
                setFieldValue,
                touched,
                errors,
                values,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                isSubmitting,
              }) => (
                <>
                  <tr>
                    <td className={style.label}>Transaction Type</td>
                    <td className={style.value}>
                      {fundedDataById?.transaction_type}
                    </td>
                    <td className={style.label}></td>
                  </tr>

                  <tr>
                    <td className={style.label}>Client Name</td>
                    <td className={style.value}>
                      {fundedDataById?.client_name}
                    </td>
                    <td className={style.label}></td>
                  </tr>
                  {fundedDataById?.obligor_name && (
                    <tr>
                      <td className={style.label}>Obligor Name</td>
                      <td className={style.value}>
                        {fundedDataById?.obligor_name}
                      </td>
                      <td className={style.label}></td>
                    </tr>
                  )}
                  {fundedDataById?.supplier_name && (
                    <tr>
                      <td className={style.label}>Supplier Name</td>
                      <td className={style.value}>
                        {fundedDataById?.supplier_name}
                      </td>
                      <td className={style.label}></td>
                    </tr>
                  )}
                  <tr>
                    <td className={style.label}>Cost</td>
                    <td className={style.value}>
                      {fundedDataById?.cost_value}
                    </td>
                    <td className={style.label}>
                      <MyInput
                        type="text"
                        name="cost_value"
                        customholder="Cost Value"
                        onBlur={(e) => {}}
                        disabled
                        InputHeight="small"
                        width="170px"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={style.label}>Face Value</td>
                    <td className={style.value}>
                      {parseFloat(fundedDataById?.face_value) +
                        parseFloat(fundedDataById?.cost_value)}
                    </td>
                    <td className={style.label}>
                      <MyInput
                        type="text"
                        name="face_value"
                        customholder="Face Value"
                        onBlur={(e) => {}}
                        disabled
                        InputHeight="small"
                        width="170px"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={style.label}>Currency</td>
                    <td className={style.value}>{fundedDataById?.currency}</td>
                    <td className={style.label}>
                      <MyDropdown
                        data={CurrencyData}
                        placeholder="Currency"
                        onChange={(option) => {
                          setFieldValue("currency", option);
                        }}
                        touched={touched.currency}
                        errors={errors.currency}
                        handleBlur={(e) => {
                          setFieldTouched("currency", true);
                          handleBlur(e);
                        }}
                        width="170px"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={style.label}>Invoice Date</td>
                    <td className={style.value}>
                      {" "}
                      {moment(fundedDataById?.invoice_date).format(
                        "YYYY-MM-DD",
                      )}
                    </td>
                    <td className={style.label}>
                      <div style={{ position: "relative", width: "170px" }}>
                        <DateSelector
                          placeholder="Invoice Date"
                          onChange={(option) => {
                            setFieldValue("invoice_date", option);
                            const todays = moment();
                            const targetDate = moment(option);

                            const diffInDays = targetDate.diff(todays, "days");
                            setFieldValue("tennor_days", diffInDays);
                          }}
                          touched={touched.due_date}
                          selectedDate={values.due_date}
                          errors={errors.due_date}
                          handleBlur={(e) => {
                            setFieldTouched("invoice_date", true);
                            handleBlur(e);
                          }}
                          disablePast
                        />
                      </div>
                    </td>
                  </tr>
                  {fundedDataById?.due_date && (
                    <tr>
                      <td className={style.label}>Due Date</td>
                      <td className={style.value}>
                        {moment(fundedDataById?.due_date).format("YYYY-MM-DD")}
                      </td>
                      <td className={style.label}>
                        <div style={{ position: "relative", width: "170px" }}>
                          <DateSelector
                            placeholder="Due Date"
                            onChange={(option) => {
                              setFieldValue("due_date", option);
                              const todays = moment();
                              const targetDate = moment(option);

                              const diffInDays = targetDate.diff(
                                todays,
                                "days",
                              );
                              setFieldValue("tennor_days", diffInDays);
                            }}
                            touched={touched.due_date}
                            selectedDate={values.due_date}
                            errors={errors.due_date}
                            handleBlur={(e) => {
                              setFieldTouched("due_date", true);
                              handleBlur(e);
                            }}
                            disablePast
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className={style.label}>Funding Date</td>
                    <td className={style.value}>
                      {moment(fundedDataById?.funded_date).format("YYYY-MM-DD")}
                    </td>
                    <td className={style.label}>
                      <div style={{ position: "relative", width: "170px" }}>
                        <DateSelector
                          placeholder="Funding Date"
                          onChange={(option) => {
                            setFieldValue("funding_date", option);
                            const todays = moment();
                            const targetDate = moment(option);

                            const diffInDays = targetDate.diff(todays, "days");
                            setFieldValue("tennor_days", diffInDays);
                          }}
                          touched={touched.due_date}
                          selectedDate={values.due_date}
                          errors={errors.due_date}
                          handleBlur={(e) => {
                            setFieldTouched("due_date", true);
                            handleBlur(e);
                          }}
                          disablePast
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={style.label}>Participant</td>
                    <td className={style.value}>
                      {fundedDataById?.funder ? fundedDataById?.funder : "--"}
                    </td>
                    <td className={style.label}>
                      <MyDropdown
                        data={CurrencyData}
                        placeholder="Participant"
                        onChange={(option) => {
                          setFieldValue("Participant", option);
                        }}
                        touched={touched.currency}
                        errors={errors.currency}
                        handleBlur={(e) => {
                          setFieldTouched("Participant", true);
                          handleBlur(e);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className={style.label}>
                      <CustomButton
                        text="Update"
                        type="btn-primary"
                        size="btn-sm"
                        style={{ marginLeft: "10px" }}
                        handleClick={() => {
                          handleSubmit(values);
                        }}
                      />
                    </td>
                  </tr>
                </>
              )}
            </Formik>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default EditFunding;
