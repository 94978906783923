import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../atoms/CustomButton";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import style from "./index.module.scss";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import Modal from "../../../../atoms/modals/DeleteModal";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { signerTypeData } from "../../../../../utils/staticData";
import moment from "moment";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../services/downloadService";
import { useSelector } from "react-redux";

const SignerManagement = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Signers;
  const [signerData, setSignerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    clientId: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}/${selectedRow.counter_id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.first_name + " " + selectedRow.last_name,
          modalId: selectedRow._id,
          clientId: selectedRow.counter_id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const GetSignerData = async () => {
    try {
      const res = await getData({
        endpoint: "/signer/getAllSigners",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          counterparty_type: filters["CounterParty Type"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setSignerData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetSignerData();
  }, [currentPage, itemsPerPage, searchData, filters]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Signer Added Yet!"
          tag="Create Signer"
          type="Pricing"
          classes="d-flex align-items-center"
          {...(roleData && roleData?.["add/edit"]
            ? {
                handleClick: () => {
                  navigate("/dashboard/signer/add");
                },
                subtitle:
                  "Currently no signer added yet click on a Create Signer button to create signer ",
              }
            : {})}
        />
      </>
    );
  });

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        console.log("Params :", params);

        return (
          <div className={style.valueStyle}>
            {capitalizeLetter(
              params?.row?.full_name,
            )}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <div className={`${style.valueStyle} no_capitalize`}>
            {params?.row?.email}
          </div>
        );
      },
    },
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.client_name}
          </div>
        );
      },
    },
    {
      field: "counter_type",
      headerName: "CounterParty Type",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.counter_type}
          </div>
        );
      },
    },
    {
      field: "counter_name",
      headerName: "CounterParty Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.counter_name}
          </div>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {moment(params.row?.updatedAt).format(
                "YYYY-MM-DD",
              )}
            </span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params, index) => {
        return (
          <>
            <>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>

              <CustomMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedRow === params.row}
                onClose={handleClose}
                items={getMenuList()}
              />
            </>
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "signer/deleteSigner",
        token: cookies.t,
        params: {
          id: modalStateDelete?.modalId,
          clientID: modalStateDelete?.clientId,
        },
      });
      if (res) {
        toast.success("Signer Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetSignerData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "signer/exportSigner",
      token: cookies.t,
      fileName: "Signers",
      params: {
        search: searchData,
        counterparty_type: filters["CounterParty Type"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Signers Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };
  const filterData = {
    "CounterParty Type": signerTypeData,
  };
  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Signers</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />
          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create Signer"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/signer/add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={signerData?.data || []}
        getRowId={(row, index) =>
      
            row?.unique_id
          
        }
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {signerData?.pageDetails?.totalCount > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={signerData?.pageDetails?.totalCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Signer"
        name={modalStateDelete.name}
      />
    </>
  );
};

export default SignerManagement;
