import { Formik } from "formik";
import * as Yup from "yup";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import MyDropdown from "../../../../../atoms/MyDropdown";
import InputLayout from "../../../../../atoms/InputLayout";
import { BrokerTypeData } from "../../../../../../utils/staticData";
import CustomButton from "../../../../../atoms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { getData, patchData, postData } from "../../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import TextInput from "../../../../../atoms/TextInput";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import CounterDropDown from "../../../../../molecules/CounterDataDropdown";
import WarningModal from "../../../../../atoms/modals/warningModal";

const AddBroker = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [editedData, setEditedData] = useState({});
  const [clientData, setClientData] = useState([]);
  const [counterData, setCounterData] = useState([]);
  const [brokerModalError, setBrokerModalError] = useState("");
  const [brokerExistError, setrBrokerExistError] = useState({
    isActive: null,
    data: null,
  });

  const initialValues = {
    client_name: editedData?.client_name || "",
    client_id: editedData?.counterDetails?.[0]?._id || "",
    broker_name: editedData?.broker_name || "",
    fee_rate: editedData?.fee_rate || "",
    broker_type: editedData?.broker_type || "",
  };

  const validationSchema = Yup.object().shape({
    client_id: Yup.string().required("Client Name is required"),
    broker_type: Yup.string().required("Broker Type is required"),
    broker_name: Yup.string().required("Broker Name is required"),
    fee_rate: Yup.number()
      .typeError("Fee Rate must be a number") // Ensure it's a number
      .required("Fee Rate is required") // Required field
      .min(0, "Fee Rate must be at least 0") // Ensure it's not negative
      .max(1, "Fee Rate must be less than 1"),
  });

  const getClientData = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Client",
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getClientData();
  }, []);

  const getCounterDataById = async (id) => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      if (res?.data && res?.data?.brokerStatus) {
        setCounterData(res?.data?.brokerStatus);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getBrokerDataById = async () => {
    try {
      const res = await getData({
        endpoint: "broker/getBrokerById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setEditedData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getBrokerDataById();
    }
  }, [id]);

  const checkbrokerExist = async (values) => {
    const response = await getData({
      endpoint: "broker/checkBroker",
      params: {
        broker_name: values.broker_name,
        broker_type: values.broker_type,
        fee_rate: values.fee_rate,
      },
      token: cookies.t,
    });
    if (response.data && response.data.status) {
      setrBrokerExistError(() => ({
        isActive: `Broker Already Exists Please Select ${response?.data?.data?.broker_name} from the Existing brokers in the list`,
        data: response?.data?.data,
      }));
    } else {
      setrBrokerExistError(() => ({
        isActive: "",
        data: "",
      }));
    }
  };

  const handleSubmit = async (values) => {
    const dataToSend = {
      counterDetails: [values?.client_id],
      broker_name: values?.broker_name,
      broker_type: values?.broker_type,
      fee_rate: values?.fee_rate,
    };
    if (id) {
      const res = await patchData({
        endpoint: "broker/update",
        token: cookies.t,
        data: dataToSend,
        params: {
          id: id,
        },
      });
      if (res) {
        toast.success("Broker Details Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/broker");
      }
    } else {
      try {
        const res = await postData({
          endpoint: "/broker/create",
          data: dataToSend,
          token: cookies.t,
        });
        if (res) {
          toast.success("Broker Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/broker");
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
          setFieldTouched,
        }) => (
          <>
            <Breadcrumb
              labels={
                type === "edit"
                  ? ["Brokers", "Update Brokers"]
                  : ["Brokers", "Create Brokers"]
              }
              excludeLastPath={type === "edit"}
            />

            <Heading className="addHeaderStyle">
              {id ? "Update" : "Create"} Broker
            </Heading>
            <div className="row">
              <div className=" col-3 mt-4 w-30">
                <MyDropdown
                  data={clientData}
                  placeholder="Client Name"
                  onChange={(option) => {
                    setFieldValue("client_id", option?.value);
                    setFieldValue("client_name", option?.label);
                  }}
                  selectedValue={values?.client_id}
                  menuOpen={true}
                  label="Client Name"
                  format="all"
                  isError={errors.client_id}
                  touched={touched.client_id}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("client_id", true);
                    getCounterDataById(values.client_id);
                  }}
                  errors={errors.client_id}
                  isDisabled={type === "edit"}
                  required
                />
              </div>

              <div className="col-3 mt-4 ">
                <InputLayout>
                  <TextInput
                    type="text"
                    name="broker_name"
                    placeholder=""
                    customholder="Broker Name"
                    customBlur={(e) => {
                      checkbrokerExist(values);
                    }}
                    InputHeight="small"
                    required
                  />
                </InputLayout>
              </div>
              <div className="col-3 mt-4">
                <InputLayout>
                  <TextInput
                    type="number"
                    name="fee_rate"
                    customholder="Fee Rate (Decimal)"
                    customBlur={(e) => {
                      checkbrokerExist(values);
                    }}
                    InputHeight="small"
                    required
                  />
                </InputLayout>
              </div>
              <div className="col-3 mt-4">
                <MyDropdown
                  placeholder="Broker Type"
                  data={BrokerTypeData}
                  isDisabled={type === "edit"}
                  onChange={(option) => {
                    setFieldValue("broker_type", option);
                    if (counterData.includes(option[0])) {
                      setBrokerModalError(
                        `You Have Already Added ${
                          option[0] === "P" ? "Primary" : "Secondary"
                        } Broker,Only One Primary Broker and One Secondary Broker Can be Accepted`,
                      );
                    } else {
                      setBrokerModalError("");
                    }
                  }}
                  selectedValue={values?.broker_type}
                  info={brokerModalError}
                  touched={touched.broker_type && !brokerModalError}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("broker_type", true);
                    checkbrokerExist(values);
                  }}
                  required
                  errors={errors.broker_type}
                />
              </div>
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={
                    !isValid || !dirty || isSubmitting || brokerModalError
                  }
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  {id ? "Update" : "Create"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
            <WarningModal
              isActive={brokerExistError?.isActive && !brokerModalError}
              title={"Broker Exists"}
              innerdescription={`These Broker whose name <b>${brokerExistError?.data?.broker_name}</b> with Fee Rate <b>${brokerExistError?.data?.fee_rate}</b>  is Already Exists,Are you proceed with these existing Broker`}
              onClose={() => {
                setFieldValue("broker_name", "");
                setFieldValue("fee_rate", "");
                setBrokerModalError("");
                setrBrokerExistError(() => ({
                  isActive: "",
                  data: "",
                }));
              }}
              onClick={() => {
                setrBrokerExistError(() => ({
                  isActive: "",
                  data: "",
                }));
              }}
            ></WarningModal>
          </>
        )}
      </Formik>
    </>
  );
};
export default AddBroker;
