import { useLocation, useParams } from "react-router-dom";
import MyDropdown from "../../../../../atoms/MyDropdown";
import { useEffect, useState } from "react";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { Heading } from "../../../../../atoms/Heading";
import FileViewer from "react-file-viewer";
import { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import ExcelToTablesFromURL from "./ExcelParser";
import * as XLSX from "xlsx";

const getFileType = (url) => {
  const extension = url?.split(".")?.pop();
  return extension?.toLowerCase();
};

const fileFormattedData = {
  financialAnalysis: "Financial Analysis",
  capIQRiskGauge: "CapIQ Risk Gauge",
  bankStatement: "Bank Statement Analysis",
  investmentMemo: "Investment Memorandum",
  summaryInvestmentMemo: "Summary Investment Memorandum",
};

const AnalysisView = () => {
  const { date, id } = useParams();
  const [cookies] = useCookies(["t", "roleGroup"]);
  const location = useLocation();
  const { name } = location.state || {};
  const [fileData, setFileData] = useState();

  const getFundingData = async () => {
    try {
      const res = await getData({
        endpoint: "analysis/getByIDandDate",
        token: cookies.t,
        params: {
          id: id,
          date: date,
        },
      });
      console.log("file-data", res?.data);
      setFileData(res?.data);
    } catch (err) {
      console.log("errr", err);
    }
  };
  useEffect(() => {
    if (id && date) {
      getFundingData();
    }
  }, []);

  return (
    <>
      <div>
        <Heading className="HeadingStyle">Analysis</Heading>
      </div>
      <div className="d-flex gap-3"></div>

      <ClientFilesTable data={fileData} />
    </>
  );
};

const ClientFilesTable = ({ data }) => {
  const [excelData, setExcelData] = useState({});
  const [requiredData, setRequiredData] = useState([]);

  // Function to fetch and parse Excel file
  const fetchAndParseExcel = async (url, category, sheetName) => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      // Find the sheet and parse it to JSON
      const worksheet = workbook.Sheets[sheetName];
      const jsonSheetData = XLSX.utils.sheet_to_json(worksheet);

      // Store the parsed data
      setExcelData((prevData) => ({
        ...prevData,
        [category]: {
          ...(prevData[category] || {}),
          [sheetName]: jsonSheetData,
        },
      }));
    } catch (error) {
      console.error("Error fetching or parsing Excel file:", error);
    }
  };

  useEffect(() => {
    // Loop through the filesData and fetch the Excel files
    if (data) {
      data?.forEach((client) => {
        Object.keys(client?.filesData).forEach((category) => {
          client?.filesData?.[category]?.forEach((fileData) => {
            fileData?.sheetsData?.forEach((sheet) => {
              fetchAndParseExcel(sheet?.data, category, sheet?.sheetName);
            });
          });
        });
      });
    }
  }, [data]);

  const handleDropdownChange = (option, index, _id) => {
    setRequiredData((prevData) => {
      // Check if there's already an entry with the same index and _id
      const existingEntryIndex = prevData.findIndex(
        (item) => item.index === index && item._id === _id,
      );

      // If it exists, replace it with the new entry
      if (existingEntryIndex !== -1) {
        const updatedData = [...prevData];
        updatedData[existingEntryIndex] = {
          required: option,
          index,
          _id,
        };
        return updatedData;
      }

      // If it doesn't exist, add the new entry
      return [
        ...prevData,
        {
          required: option,
          index,
          _id,
        },
      ];
    });
  };

  console.log("excelData", excelData);
  console.log("data", data);

  console.log("req-data", requiredData);

  return (
    <>
      {data && (
        <div>
          {data?.map((client, index) => {
            console.log("cli", client);
            const filesData = client?.filesData;
            const dropdownData = Object.keys(filesData)?.map((item) => ({
              label: fileFormattedData?.[item],
              value: item,
            }));

            return (
              <div key={index}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex gap-3">
                    <h2 className="HeadingSubStyle">
                      Client: {client?.client_name}{" "}
                    </h2>
                    <h2 className="HeadingSubStyle">
                      Date:{" "}
                      {new Date(client?.selected_date).toLocaleDateString()}
                    </h2>
                  </div>

                  <MyDropdown
                    placeholder="Select Sheet"
                    data={dropdownData}
                    onChange={(option) => {
                      console.log("op", option);
                      handleDropdownChange(option, index, client?._id);
                    }}
                    handleBlur={(e) => {
                      //   setFieldTouched("client_id", true);
                      //   handleBlur(e);
                    }}
                    // selectedValue={counterData?.client_id}
                    // format="all"
                    selectedValue={requiredData?.[index]?.required}
                    menuOpen={true}
                    name="client_id"
                    // touched={touched.client_id}
                    // errors={errors.client_id}

                    isClearable={() => {}}
                    width="32.426%"
                  />
                </div>

                <div>
                  {Object.keys(client?.filesData).map((category, idx) => (
                    <div key={idx}>
                      <h3 className="HeadingSubStyle mb-0">
                        {fileFormattedData?.[category]}
                      </h3>
                      {client?.filesData?.[category]?.map(
                        (fileData, fileIdx) => {
                          console.log('fileData',fileData)
                          return (
                            <div key={fileIdx}>
                              {/* {getFileType(fileData?.s3UploadedLink) ===
                                "pdf" && (
                                <FileViewer
                                  fileType="pdf"
                                  filePath={fileData?.s3UploadedLink}
                                  pluginRenderers={PDFRenderer}
                                  
                                />
                              )} */}

                              {/* {(getFileType(fileData?.s3UploadedLink) === 'xls' || getFileType(fileData?.s3UploadedLink) === 'xlsx') && 
                              <ExcelToTablesFromURL fileUrl={fileData?.s3UploadedLink}/>
                              } */}
                            </div>
                          );
                        },
                      )}
                    </div>
                  ))}
                  <hr />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

// Styles
const styles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
    minWidth: "500px", // Ensures a scroll if table overflows
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f4f4f4",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left", // Optional for alignment consistency
  },
};

export default AnalysisView;
