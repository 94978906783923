import { Formik } from "formik";
import * as Yup from "yup";
import style from "./index.module.scss";
import { Heading } from "../../../../../atoms/Heading";
import MyDropdown from "../../../../../atoms/MyDropdown";
import { signerTypeData } from "../../../../../../utils/staticData";
import InputLayout from "../../../../../atoms/InputLayout";
import CustomButton from "../../../../../atoms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { getData, patchData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useEffect, useState } from "react";
import TextInput from "../../../../../atoms/TextInput";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import CounterDropDown from "../../../../../molecules/CounterDataDropdown";
import TagDropdown from "../../../../../atoms/MyDropdown/TagDropdown";
import { errorStyles } from "../../../../../../assets/styles/components/dropdown";
import { emailRegex } from "../../../../../../helpers/expirationTime";
import WarningModal from "../../../../../atoms/modals/warningModal";
import { isAction } from "@reduxjs/toolkit";

const AddSigner = ({ type }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const { id, counter_id } = useParams();
  const [signerEditedData, setSignerData] = useState({});
  const [supplierObligorData, setSupplierObligorData] = useState([]);
  const [clientData, setClientData] = useState();
  const [emailError, setEmailError] = useState({
    isActive: null,
    data: null,
  });

  const initialValues = {
    client_name: signerEditedData?.counterDetails?.[0]?.client_name || "",
    client_id:
      signerEditedData?.counterDetails?.[0]?.counter_id?.counter_type ===
        "Supplier" ||
      signerEditedData?.counterDetails?.[0]?.counter_id?.counter_type ===
        "Obligor"
        ? signerEditedData?.counterDetails?.[0]?.counter_id?.client_id
        : signerEditedData?.counterDetails?.[0]?.counter_id?._id || "",
    first_name: signerEditedData?.first_name || "",
    last_name: signerEditedData?.last_name || "",
    email: signerEditedData?.email || "",
    Supplier_Obligor:
      signerEditedData?.counterDetails?.[0]?.counter_type === "Supplier" ||
      signerEditedData?.counterDetails?.[0]?.counter_type === "Obligor"
        ? signerEditedData?.counterDetails?.[0]?._id
        : "",

    signer_type:
      signerEditedData?.counterDetails?.[0]?.counter_id?.counter_type || "",
  };

  const validationSchema = Yup.object().shape({
    client_id: Yup.string().required("Client Name is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
    signer_type: Yup.string().required("Signer type is required"),
    Supplier_Obligor: Yup.string().when("signer_type", {
      is: (value) => value === "Supplier/Obligor",
      then: () => Yup.string().required("Supplier/Obligor is required"),
      otherwise: () => Yup.string().nullable(),
    }),
  });

  const getSupplierObligor = async ({ client_id, type }) => {
    const res = await getData({
      endpoint: "couterParty/clientSupplierAndObligor",
      params: { id: client_id, counterparty_type: [type] },
      token: cookies.t,
    });
    setSupplierObligorData(res?.data);
  };
  const getCounterPartyDataByType = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Client",
        },
      });
      console.log("res", res);
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getCounterPartyDataByType();
  }, []);

  const getSignerDataById = async () => {
    try {
      let res = await getData({
        endpoint: "signer/getSignerById",
        token: cookies.t,
        params: {
          id: id,
        },
      });

      res = {
        ...res?.data, // Spread the existing data
        counterDetails: res?.data?.counterDetails?.filter(
          (item) => item?.counter_id?._id !== counter_id, // Filter out the item where counter_id._id matches counter_id
        ),
      };
      setSignerData(res);
      getSupplierObligor({
        client_id: counter_id,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getSignerDataById();
    }
  }, [id]);

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "signer/checkSignerExist",
      params: {
        email: newVal,
      },
      token: cookies.t,
    });
    if (response.data && response.data.status) {
      if (type === "edit" ? newVal !== signerEditedData.email : true)
        setEmailError(() => ({
          isActive: "Email Already Exists",
          data: response?.data?.data,
        }));
    } else {
      setEmailError(() => ({
        isActive: "",
        data: "",
      }));
    }
  };

  const handleSubmit = async (values) => {
    const dataToClient = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      counterDetails: [
        values?.signer_type === "Supplier" || values?.signer_type === "Obligor"
          ? values?.Supplier_Obligor
          : values?.client_id,
      ],
    };
    // const dataToSuppilerObligor = {
    //   first_name: values?.first_name,
    //   last_name: values?.last_name,
    //   email: values?.email,
    //   clientDetails: [
    //     {
    //       client_name: values?.client_name,
    //       client_id: values?.client_id,
    //       client_status: "Active",
    //       counterParty_name: values?.Supplier_Obligor,
    //       counterParty_type: values?.signer_type,
    //     },
    //   ],
    // };
    if (id) {
      try {
        const res = await patchData({
          endpoint: "signer/updateSigner",
          token: cookies.t,
          params: {
            id: id,
            counter_id: counter_id,
          },
          data: dataToClient,
        });
        if (res) {
          toast.success("Signer Details Update Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/signer");
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      try {
        const res = await postData({
          endpoint: "signer/createSigner",
          token: cookies.t,
          data: dataToClient,
        });
        if (res?.data?.statusCode === 406) {
          toast.error("Dublicate entry", {
            duration: 1000,
            style: errorStyles,
          });
        } else {
          toast.success("Signer Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/signer");
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          isSubmitting,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          resetForm,
        }) => (
          <>
            <Breadcrumb
              labels={
                type === "edit"
                  ? ["Signer", "Update Signer"]
                  : ["Signer", "Create Signer"]
              }
              stepsToRemove={type === "edit" ? 2 : 0}
              // excludeLastPath={type === "edit"}
            />

            <Heading className="addHeaderStyle">
              {id ? "Update" : "Create"} Signer
            </Heading>
            <div className="row">
              <div className="col-3 mt-4">
                <MyDropdown
                  placeholder="Signer Type"
                  data={signerTypeData}
                  onChange={(option) => {
                    setFieldValue("signer_type", option);
                  }}
                  selectedValue={values?.signer_type}
                  isError={errors.signer_type}
                  touched={touched.signer_type}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("signer_type", true);
                  }}
                  errors={errors.signer_type}
                  required
                />
              </div>
              <div className="col-3 mt-4">
                {/* <CounterDropDown
                  placeholder="Client Name"
                  onChange={(option) => {
                    setFieldValue("client_name", option.label);
                    setFieldValue("client_id", option?.value);
                    getSupplierObligor({ client_id: option?.value });
                  }}
                  selectedValue={values?.client_id}
                  format="all"
                  isError={errors.client_id}
                  touched={touched.client_id}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("client_id", true);
                  }}
                  errors={errors.client_id}
                /> */}
                <TagDropdown
                  placeholder="Client Name"
                  data={clientData}
                  onChange={(option) => {
                    setFieldValue("client_name", option.label);
                    setFieldValue("client_id", option?.value);
                    getSupplierObligor({
                      client_id: option?.value,
                      type: values?.signer_type,
                    });
                  }}
                  selectedValue={values?.client_id}
                  format="all"
                  isError={errors.client_id}
                  touched={touched.client_id}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("client_id", true);
                  }}
                  errors={errors.client_id}
                  required
                />
              </div>
              {(values?.signer_type === "Supplier" ||
                values?.signer_type === "Obligor") && (
                <>
                  <div className="col-3 mt-4">
                    <TagDropdown
                      placeholder={`Counterparty ${values.signer_type}`}
                      data={supplierObligorData}
                      onChange={(option) => {
                        setFieldValue("Supplier_Obligor", option);
                      }}
                      selectedValue={values?.Supplier_Obligor}
                      isError={errors.Supplier_Obligor}
                      touched={touched.Supplier_Obligor}
                      handleBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("Supplier_Obligor", true);
                      }}
                      errors={errors.Supplier_Obligor}
                      tagSplit={false}
                      required
                    />
                  </div>

                  <div className="col-3"></div>
                </>
              )}

              <div className="col-3 mt-4">
                <InputLayout>
                  <TextInput
                    type="text"
                    name="first_name"
                    customholder="First Name"
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    InputHeight="small"
                    required
                  />
                </InputLayout>
              </div>
              <div className="col-3 mt-4">
                <InputLayout>
                  <TextInput
                    type="text"
                    name="last_name"
                    placeholder=""
                    customholder="Last Name"
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    InputHeight="small"
                    required
                  />
                </InputLayout>
              </div>
              <div className="col-3 mt-4">
                <InputLayout>
                  <TextInput
                    type="text"
                    name="email"
                    placeholder=""
                    customholder="Email"
                    customBlur={(e) => {
                      checkEmail(values.email);
                    }}
                    InputHeight="small"
                    blurError={emailError.isActive}
                    required
                  />
                </InputLayout>
              </div>
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={
                    !isValid || !dirty || isSubmitting || !!emailError.isActive
                  }
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  {id ? "Update" : "Create"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
            <WarningModal
              isActive={emailError?.isActive}
              title={"Email Exists"}
              innerdescription={`These Signer whose name <b>${emailError?.data?.first_name} ${emailError?.data?.last_name}</b> with email <b>${emailError?.data?.email}</b>  is Already Exists,Are you proceed with these existing Signer`}
              onClose={() => {
                setFieldValue("email", "");
                setEmailError(() => ({
                  isActive: "",
                  data: "",
                }));
              }}
              onClick={() => {
                setFieldValue("first_name", emailError?.data?.first_name);
                setFieldValue("last_name", emailError?.data?.last_name);
                setEmailError(() => ({
                  isActive: "",
                  data: "",
                }));
              }}
            ></WarningModal>
          </>
        )}
      </Formik>
    </>
  );
};
export default AddSigner;
