import { useCookies } from "react-cookie";
import { getData, patchData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import style from "./index.module.scss";
import { memo, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import TabStrip from "../../../../../atoms/tabstrip";
import { Box } from "@mui/system";
import { Heading } from "../../../../../atoms/Heading";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../../atoms/NoContentCard";
import Pagination from "../../../../../atoms/Pagination";

const SubmittedModal = ({
  modalStateSubmitted,
  setModalStateSubmitted,
  getAllFundingStatus,
}) => {
  const [cookies] = useCookies(["t"]);
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("Credit info");

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const data = [
    {
      id: 1,
      label: "Credit info",
      view: <CreditInfo fundingData={fundingData} clientData={clientData} />,
    },
    {
      id: 1,
      label: "See Dues",
      view: <SeeObligorDues fundingData={fundingData} />,
    },
  ];

  const getFundingById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: modalStateSubmitted.modalId,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getClientById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: fundingData?.client_id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (fundingData?.client_id) {
      getClientById();
    }
  }, [fundingData?.client_id]);

  useEffect(() => {
    if (modalStateSubmitted.modalId) {
      getFundingById();
    }
  }, [modalStateSubmitted.modalId]);

  const handleRejectFunding = async () => {
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateSubmitted.modalId,
        },
        data: {
          status: "Reject",
        },
      });
      if (res) {
        toast.success("Funding Rejected Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      setModalStateSubmitted(() => ({
        isModalOpen: false,
      }));
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleApproveFunding = async () => {
    try {
      setIsLoading(true);
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateSubmitted.modalId,
        },
        data: {
          status: "Approved",
        },
      });
      if (res) {
        toast.success("Funding Submitted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        setIsLoading(false);
      }
      setModalStateSubmitted(() => ({
        isModalOpen: false,
      }));
      getAllFundingStatus();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>

      <div className={style.buttonStyle}>
        {/* <CustomButton
          text="Approve"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            handleApproveFunding();
          }}
        /> */}
        <button
          className="btn-primary btn px-5"
          type="submit"
          onClick={() => {
            handleApproveFunding();
          }}
        >
          Approve
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm ms-2 text-light"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <CustomButton
          text="Reject"
          type="btn-primary"
          size="btn"
          classes={'px-5'}
          handleClick={() => {
            handleRejectFunding();
          }}
        />
        <CustomButton
          text="Cancel"
          type="btn-primary"
          size="btn"
          classes={'px-5'}
          handleClick={() => {
            setModalStateSubmitted(() => ({
              isModalOpen: false,
            }));
          }}
        />
      </div>
    </>
  );
};
export default SubmittedModal;
//Sub module
const CreditInfo = ({ fundingData, clientData }) => {
  return (
    <>
      <div>
        <div className="row">
          <label className={style.terms}>Asset Info</label>
         
          <div className="col-6">
            <div className={style.label}>Cost</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.cost_value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Face Value</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {parseFloat(fundingData.face_value) +
                parseFloat(fundingData?.cost_value)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Currency</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.currency}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Schedule status</div>
          </div>
          <div className="col-6">
            <div className={style.value}>document complete</div>
          </div>
        </div>
        <div className="row">
          <label className={style.terms}>Obligor Information</label>
          <div className="col-6">
            <div className={style.label}>Name</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.counter_name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Internal Credit Limit</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.credit_limit}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Credit Used</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.cost_value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Credit Remaining</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {parseFloat(clientData?.credit_limit) -
                parseFloat(fundingData?.cost_value)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SeeObligorDues = ({ fundingData }) => {
  const [cookies] = useCookies(["t"]);
  const [duesData, setDuesData] = useState([]);

  const getDueData = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingByClientId",
        params: {
          id:
            fundingData?.transaction_type === "Client"
              ? fundingData?.client_id
              : fundingData?.transaction_type === "Supplier"
              ? fundingData?.Supplier_id
              : fundingData?.transaction_type === "Obligor"
              ? fundingData?.obligor_id
              : "",
          status: "Funded",
          trans_type: fundingData?.transaction_type,
        },
        token: cookies.t,
      });
      setDuesData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (fundingData) {
      getDueData();
    }
  }, [fundingData]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Content Found"
          subtitle=""
          type="See Dues"
          classes="d-flex align-items-center"
          height={'7rem'}
          fontSize={'18px'}
        />
      </>
    );
  });
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.obligor_name}</div>;
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.supplier_name}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{parseFloat(params?.row?.cost_value)}</div>;
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div>
            {parseFloat(params?.row?.face_value) +
              parseFloat(params?.row?.cost_value)}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.transaction_type}</div>;
      },
    },
  ];

  console.log("duesData", duesData);
  return (
    <>
      <CustomDatagrid
        rows={duesData}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="23rem"
      />
      {/* {duesData?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={fundingData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )} */}
    </>
  );
};
